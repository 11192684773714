(function(angular){
    'use strict';


    angular.module("app")
        .controller("SettingsController", SettingsController) // Page common $scope
        .controller("SettingsUserController", SettingsUserController) // Users
        .controller("SettingsSystemController", SettingsSystemController) // System
        .controller("SettingsAccountController", SettingsAccountController) // Account
        .controller("ImportController", ImportController); //Import

    function SettingsController($rootScope, $scope, $uibModal, _apiService, Notification, _v, _view, _i18n) {
        var vm = this;

        vm.currentUser = null;
        vm.account = null;

        $rootScope.isLoading = true;

        $scope.$on("_view:urlReady", init);

        /**
         * Close settings page
         */
        vm.exit = function() {
            _v.change({set: {"settings": undefined}});
        };

        /**
         * Init controller
         */
        function init() {
            /**
             * Get current user
             * @property {Object} currentUser.accounts - all accounts available for current user
             */
            vm.currentUser = _view.getCurrentUser();
            $scope.currentUser = vm.currentUser;

            /**
             * Get current account
             */
            vm.account = vm.currentUser.accounts.find(account => account.id == _view.getCurrentAccount());
            $scope.account = vm.account;
        }

        /**
         * Edit account name
         */
        vm.editAccount = function() {
            var $uibModalInstance = $uibModal.open({
                openedClass: 'modal-open modal-settings',
                templateUrl: 'modal-editAccount',
                resolve: {
                    accountName: () => $scope.account.name
                },
                controllerAs: 'editAccount',
                controller: function($uibModalInstance, accountName) {
                    var vm = this;

                    vm.accountName = accountName;

                    vm.ok = () => $uibModalInstance.close(vm.accountName);
                    vm.cancel = () => $uibModalInstance.dismiss('cancel');
                }
            });

            $uibModalInstance.result.then(accountName => {
                $rootScope.isLoading = true;
                _apiService.updateAccount(vm.account.id, accountName).then(
                    () => {
                        $scope.account.name = accountName;

                        $rootScope.isLoading = false;
                        Notification.success(_i18n.getString('settings.notifications.updateAccount.success'));
                    },
                    () => {
                        $rootScope.isLoading = false;
                        Notification.error(_i18n.getString('settings.notifications.updateAccount.error'));
                    }
                );
            });
        };
    }

    function SettingsUserController($rootScope, $scope, $uibModal, _apiService, Notification, _view, _i18n, _flashService, _appStorage, gettextCatalog) {
        var vm = this;

        $scope.$on("_view:urlReady", init);

        vm.users = {};
        vm.invitations = {};

        /**
         * Modals
         */

        vm.addUser = addUser;
        vm.editUser = editUser;
        vm.invitationDelete = invitationDelete;
        vm.invitationResend = invitationResend;

        $scope.readURL = function (element) {
            $rootScope.isLoading = true;

            _apiService.avatarUpload($scope.account.id, element.files[0]).then(
                response => {
                    _flashService.put('notification-success', gettextCatalog.getString('Avatar changed'));
                    localStorage.setItem('noSplashScreen', true);
                    location.reload();
                },
                () => {
                    $rootScope.isLoading = false;
                    Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                }
            );
        };

        vm.getAvatarUrl = function (user) {

            if (user && user.avatarUrl) {
                return "/avatar/" + user.avatarUrl;
            }

            return "/img/empty_ava.jpg";
        };

        /**
         * Init controller
         */
        function init() {
            if (!$scope.account) {
                $rootScope.isLoading = false;
                return;
            }

            $rootScope.isLoading = true;

            /**
             * Get available users
             */
            _apiService.accountUsers($scope.account.id).then(
                response => {
                    var users = response.data.users;
                    var invitations = response.data.invitations;

                    // Add role name
                    users.forEach(user => user.roleName = _i18n.getString("settings.roles." + user.role));
                    invitations.forEach(user => user.roleName = _i18n.getString("settings.roles." + user.role));

                    // Make current account first
                    users = _.sortBy(users, user => user.id != $scope.currentUser.id);

                    vm.users = users;
                    vm.invitations = invitations;

                    $rootScope.isLoading = false;

                    var data;
                    if (data = _flashService.get('notification-success')) {
                        Notification.success(data);
                    }
                },
                () => {
                    $rootScope.isLoading = false;
                    Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                }
            );
        }

        /**
         * Add a new user
         */
        function addUser() {
            var $uibModalInstance = $uibModal.open({
                openedClass: 'modal-open modal-settings',
                templateUrl: 'modal-addUser',
                controllerAs: 'addUser',
                controller: function($uibModalInstance, _i18n) {
                    var vm = this;

                    // Set up some defaults
                    vm.user = {
                        language: "ru",
                        units: "METRIC",
                        tempScale: "C",
                        role: "ROLE_ACCOUNT_EDIT"
                    };

                    // Possible roles
                    vm.roles = ["ROLE_ACCOUNT_OWNER", "ROLE_ACCOUNT_EDIT"].map(item => {
                        return {
                            id: item,
                            name: _i18n.getString("settings.roles." + item)
                        };
                    });

                    vm.ok = () => $uibModalInstance.close(vm.user);
                    vm.cancel = () => $uibModalInstance.dismiss('cancel');
                }
            });

            $uibModalInstance.result.then(user => {
                $rootScope.isLoading = true;

                _apiService.saveUser($scope.account.id, user).then(
                    response => {
                        // response.data = id of invited user
                        // Todo: user may already exist therefore firstName, lastName won't be correct
                        user.id = response.data;
                        vm.invitations.push(user);

                        $rootScope.isLoading = false;
                        Notification.success(_i18n.getString("settings.notifications.saveUser.success", {"userName": user.firstName}))
                    },
                    () => {
                        $rootScope.isLoading = false;
                        Notification.error(_i18n.getString("settings.notifications.saveUser.error"));
                    }
                );
            });
        }

        /**
         * Edit existing user
         *
         * @param {Number} userID
         */
        function editUser(userID) {
            var user = vm.users.find(user => user.id == userID);

            var $uibModalInstance = $uibModal.open({
                openedClass: 'modal-open modal-settings',
                templateUrl: 'modal-editUser',
                resolve: {
                    user: () => _.clone(user),
                    currentUser: () => $scope.currentUser
                },
                controllerAs: 'editUser',
                controller: function ModalEditUserController($uibModalInstance, _i18n, user, currentUser) {
                    var vm = this;

                    vm.user = user;
                    vm.user.password = {};
                    vm.currentUser = currentUser;

                    // Possible roles
                    vm.roles = ["ROLE_ACCOUNT_OWNER", "ROLE_ACCOUNT_EDIT"].map(item => {
                        return {
                            id: item,
                            name: _i18n.getString("settings.roles." + item)
                        };
                    });

                    vm.ok = method => {
                        vm.user.method = method;

                        $uibModalInstance.close(vm.user);
                    };

                    vm.cancel = () => $uibModalInstance.dismiss('cancel');
                }
            });

            $uibModalInstance.result.then(_user => {
                $rootScope.isLoading = true;

                var user = _user,
                    method = user.method;

                delete user.roleName;
                delete user.method;

                if (method == 'delete') {
                    _deleteUser(user.id);
                } else if (method == 'password_change') {
                    _changePassword(user.password);
                } else {
                    _editUser(user);
                }
            });

            /**
             * @param userID
             * @private
             */
            function _deleteUser(userID) {
                _apiService.deleteUser($scope.account.id, userID).then(
                    () => {
                        // Update users
                        vm.users = vm.users.filter(user => user.id != userID);

                        $rootScope.isLoading = false;
                        Notification.success('User Account was deleted');
                    },
                    () => {
                        $rootScope.isLoading = false;
                        Notification.error('User Account was not deleted');
                    }
                );
            }

            /**
             * @param user
             * @private
             */
            function _editUser(user) {
                _apiService.updateUser($scope.account.id, user).then(
                    () => {
                        user.roleName = _i18n.getString("settings.roles." + user.role);

                        // Update $scope.users
                        vm.users.forEach((u, index) => {
                            if (u.id == user.id) {
                                vm.users[index] = user;
                            }
                        });

                        // Update language
                        if (user.id == $scope.currentUser.id && _appStorage.get('lang') != user.language) {
                            _appStorage.set('lang', user.language);
                            _flashService.put('notification-success', _i18n.getString("settings.notifications.updateUser.success"));
                            localStorage.setItem('noSplashScreen', true);
                            location.reload();
                        } else {
                            $rootScope.isLoading = false;
                            Notification.success(_i18n.getString("settings.notifications.updateUser.success"));
                        }
                    },
                    () => {
                        $rootScope.isLoading = false;
                        Notification.error(_i18n.getString("settings.notifications.updateUser.error"));
                    }
                );
            }

            /**
             * @param password
             * @property password.old - old password
             * @property password.new - new password
             * @property password.confirm - confirm new password
             * @private
             */
            function _changePassword(password) {
                _apiService.updatePassword(password.old, password.new, password.confirm).then(
                    () => {
                        $rootScope.isLoading = false;
                        Notification.success('Your password was successfully changed');
                    },
                    () => {
                        $rootScope.isLoading = false;
                        Notification.error('Your password was not changed');
                    }
                )
            }
        }

        /**
         * Delete invited user
         *
         * @param {Number} userID
         */
        function invitationDelete(userID) {
            $rootScope.isLoading = true;

            _apiService.deleteInvitation($scope.account.id, userID).then(
                () => {
                    // Update $scope.invitations
                    vm.invitations = vm.invitations.filter(user => user.id != userID);

                    $rootScope.isLoading = false;
                    Notification.success(_i18n.getString("settings.notifications.deleteInvitation.success"));
                },
                () => {
                    $rootScope.isLoading = false;
                    Notification.error(_i18n.getString("settings.notifications.deleteInvitation.error"));
                }
            )
        }

        /**
         * Re-invite invited user
         *
         * @param {Number} userID
         */
        function invitationResend(userID) {
            $rootScope.isLoading = true;

            _apiService.resendInvitation($scope.account.id, userID).then(
                () => {
                    $rootScope.isLoading = false;
                    Notification.success(_i18n.getString("settings.notifications.resendInvitation.success"));
                },
                () => {
                    $rootScope.isLoading = false;
                    Notification.error(_i18n.getString("settings.notifications.resendInvitation.error"));
                }
            )
        }
    }

    /**
     * System settings
     */
    function SettingsSystemController($rootScope, $scope, _apiService, Notification, _i18n) {
        var vm = this;

        vm.notChanged = true;

        vm.daysOfTheWeek = moment.weekdays().map((item, i) => {
            return {
                id: i,
                label: item
            }
        });

        vm.firstDayOfWeek = vm.daysOfTheWeek.find(day => day.id == ($scope.account.settings.firstDayOfWeek || 0));
        var firstDayOfWeekInitial = vm.firstDayOfWeek.id;

        /**
         * @param {Object} day
         * @property {Number} day.id
         * @property {String} day.label
         */
        vm.setFirstDayOfWeek = function(day) {
            $scope.account.settings.firstDayOfWeek = day.id;
            vm.notChanged = (day.id == firstDayOfWeekInitial);
        };

        /**
         * Save settings
         */
        vm.saveSettings = function() {
            $rootScope.isLoading = true;

            _apiService.updateAccount($scope.account.id, $scope.account.name, $scope.account.settings).then(
                () => {
                    vm.notChanged = true;
                    firstDayOfWeekInitial = vm.firstDayOfWeek.id;
                    $rootScope.isLoading = false;
                    Notification.success(_i18n.getString("settings.notifications.updateAccountFirstDayOfWeek.success"));
                },
                () => {
                    $rootScope.isLoading = false;
                    Notification.error(_i18n.getString("settings.notifications.updateAccountFirstDayOfWeek.error"));
                }
            );
        }
    }

    /**
     * Account settings
     *
     * Available methods:
     *  - addAccount
     *
     * Todo:
     *  - delete account
     *  - pause account
     */
    function SettingsAccountController($rootScope, $uibModal, Notification, _apiService, _flashService, _i18n) {
        var vm = this;

        vm.addAccount = addAccount;

        // todo:
        vm.deleteAccount = deleteAccount;
        vm.pauseAccount = pauseAccount;

        /**
         * Add a new account
         */
        function addAccount() {
            var $uibModalInstance = $uibModal.open({
                openedClass: 'modal-open modal-settings',
                templateUrl: 'modal-addAccount',
                controllerAs: 'addAccount',
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.accountName = null;

                    vm.ok = () => $uibModalInstance.close(vm.accountName);
                    vm.cancel = () => $uibModalInstance.dismiss('cancel');
                }
            });

            $uibModalInstance.result.then(accountName => {
                $rootScope.isLoading = true;
                _apiService.addAccount(accountName)
                    .then(
                        () => {
                            _flashService.put('notification-success', _i18n.getString("settings.notifications.addAccount.success", {"accountName": accountName}));
                            localStorage.setItem('noSplashScreen', true);
                            location.reload();
                        },
                        () => {
                            Notification.error(_i18n.getString("settings.notifications.addAccount.error"));
                            $rootScope.isLoading = false
                        }
                    );
            });
        }

        function deleteAccount() {}

        function pauseAccount() {}
    }

    function ImportController($rootScope, $scope, Notification, _apiService, _flashService, _i18n, gettextCatalog) {

        $scope.importFields = function (element) {
            $rootScope.isLoading = true;

            _apiService.uploadFields($scope.account.id, element.files[0]).then(
                response => {
                    _flashService.put('notification-success', gettextCatalog.getString('Fields imported'));
                    localStorage.setItem('noSplashScreen', true);
                    location.reload();
                },
                () => {
                    $rootScope.isLoading = false;
                    Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                });
        }
    }

}(angular));

(function () {
    'use strict';
    var directiveId = 'ngMatch';
    angular.module("app")
        .directive(directiveId, ['$parse', function ($parse) {

            var directive = {
                link: link,
                restrict: 'A',
                require: '?ngModel'
            };
            return directive;

            function link(scope, elem, attrs, ctrl) {
                // if ngModel is not defined, we don't need to do anything
                if (!ctrl) {
                    return;
                }
                if (!attrs[directiveId]) {
                    return;
                }

                var firstPassword = $parse(attrs[directiveId]);

                var validator = function (value) {
                    var temp = firstPassword(scope),
                        v = value === temp;
                    ctrl.$setValidity('match', v);
                    return value;
                };

                ctrl.$parsers.unshift(validator);
                ctrl.$formatters.push(validator);
                attrs.$observe(directiveId, function () {
                    validator(ctrl.$viewValue);
                });
            }
        }]);
})();

(function () {
    'use strict';

    angular.module("app")
        .directive('ngThumb', ngThumb);

    function ngThumb($window) {
        var helper = {
            support: !!($window.FileReader && $window.CanvasRenderingContext2D),
            isFile: function (item) {
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage: function (file) {
                var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        };

        return {
            restrict: 'A',
            template: '<canvas/>',
            link: function (scope, element, attributes) {
                if (!helper.support) return;

                var params = scope.$eval(attributes.ngThumb);

                if (!helper.isFile(params.file)) return;
                if (!helper.isImage(params.file)) return;

                var canvas = element.find('canvas');
                var reader = new FileReader();

                reader.onload = onLoadFile;
                reader.readAsDataURL(params.file);

                function onLoadFile(event) {
                    var img = new Image();
                    img.onload = onLoadImage;
                    img.src = event.target.result;
                }

                function onLoadImage() {
                    var width = params.width || this.width / this.height * params.height;
                    var height = params.height || this.height / this.width * params.width;
                    canvas.attr({width: width, height: height});
                    canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
                }
            }
        };
    }
})();